import { useState, useEffect } from "react";

// Convert DMS to Decimal Degrees
const convertDmsToDecimal = (dms) => {
  const dmsParts = dms.split(/[°'"\s]+/).filter(Boolean); // Split into parts (degrees, minutes, seconds)
  
  if (dmsParts.length < 3) {
    throw new Error("Invalid DMS format.");
  }

  const degrees = parseFloat(dmsParts[0].trim());
  const minutes = parseFloat(dmsParts[1].trim());
  const seconds = parseFloat(dmsParts[2].trim());
  
  const direction = dms.trim().slice(-1); // Last character is the direction

  let decimalDegrees = degrees + minutes / 60 + seconds / 3600;

  // If South or West, negate the value
  if (direction === 'S' || direction === 'W') {
    decimalDegrees *= -1;
  }

  return decimalDegrees;
};

const useGeoData = (postCode) => {
  const [coordinates, setCoordinates] = useState({ latitude: null, longitude: null });

  useEffect(() => {
    if (postCode) {
      const apiUrl = `https://api.opencagedata.com/geocode/v1/json?q=${postCode}&key=6bd2e50a75924061b83d8f50e760d4ef&language=en&pretty=1`;

      fetch(apiUrl)
        .then((response) => response.json())
        .then((json) => {
          const latDMS = json.results[0].annotations.DMS.lat;
          const lngDMS = json.results[0].annotations.DMS.lng;
          
          // Convert DMS to decimal
          const latitude = convertDmsToDecimal(latDMS);
          const longitude = convertDmsToDecimal(lngDMS);
          
          setCoordinates({ latitude, longitude });
        })
        .catch((error) => console.error("Error fetching data:", error));
    }
  }, [postCode]);

  return coordinates;
};

export default useGeoData;
