import React, { useState, useRef, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import DatePicker from "react-datepicker";
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import "react-datepicker/dist/react-datepicker.css";

function GetTime() {
  const location = useLocation();
  const [schedule, setSchedule] = useState([]);
  const { instructorId, pricing, gearBoxName, hours } = location.state || {};
  const [isLoading, setIsLoading] = useState(true);
  const [instructorData, setInstructorData] = useState(null);
  const [selectedDate, setSelectedDate] = useState('');
  const [availableTimes, setAvailableTimes] = useState([]);
  const [selectedTime, setSelectedTime] = useState('');
  const [selectedScheduleId, setSelectedScheduleId] = useState(null);
  const [calendarOpen, setCalendarOpen] = useState(false);

  const navigate = useNavigate();
  const sliderRef = useRef(null);

  const handleCalendarToggle = () => {
    setCalendarOpen((prevState) => !prevState);
  };
  const handleDateChange = (date) => {
    const formattedDate = date.toISOString().split("T")[0];
    setSelectedDate(formattedDate); // Set the selected date in the state
    setCalendarOpen(false); // Close the calendar after selection

    const filteredTimes = schedule
      .filter((entry) => entry.sessionDate === formattedDate && !entry.isRunning)
      .map((entry) => entry.sessionTime);

    setAvailableTimes(Array.from(new Set(filteredTimes)));
    setSelectedTime("");
    setSelectedScheduleId(null); // Reset schedule ID when changing date
  };

  useEffect(() => {
    fetch('https://csmserver.csmdrivingschool.ca/InstructorSchedule.php')
      .then((response) => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then((data) => {
        if (Array.isArray(data)) {
          const filteredSchedule = data.filter((entry) => entry.instructorUsername === instructorId);
          setSchedule(filteredSchedule);

        } else {
          console.error('Unexpected data format:', data);
        }
      })
      .catch((error) => {
        console.error('Error fetching schedule data:', error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [instructorId]);

  useEffect(() => {
    if (instructorId) {
      fetch(`https://csmserver.csmdrivingschool.ca/Instructor.php?id=${instructorId}`)
        .then((response) => {
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          return response.json();
        })
        .then((data) => {
          setInstructorData(data);

        })
        .catch((error) => {
          console.error('Error fetching instructor data:', error);
        });
    }
  }, [instructorId]);

  const isFutureOrToday = (dateString) => {
    const today = new Date();
    const date = new Date(dateString);

    today.setHours(0, 0, 0, 0);
    date.setHours(0, 0, 0, 0);
    return date >= today;
  };

  const uniqueDates = Array.from(
    new Set(schedule.map((entry) => entry.sessionDate))
  )
    .filter(isFutureOrToday)
    .map((date) => {
      const entry = schedule.find((item) => item.sessionDate === date);
      const day = new Date(date).toLocaleDateString('en-US', { weekday: 'short' }).toUpperCase();
      const month = new Date(date).toLocaleDateString('en-US', { month: 'short' });
      const dayOfMonth = new Date(date).getDate();

      const uniqueTimes = Array.from(
        new Set(schedule
          .filter((item) => item.sessionDate === date && !item.isRunning)
          .map((item) => item.sessionTime))
      );

      return { day, date, month, fullDate: date, dayOfMonth, uniqueTimes };
    });

  const handleDateSelection = (date) => {
    setSelectedDate(date);

    const filteredTimes = schedule
      .filter((entry) => entry.sessionDate === date && !entry.isRunning)
      .map((entry) => entry.sessionTime);

    setAvailableTimes(Array.from(new Set(filteredTimes)));
    setSelectedTime('');
    setSelectedScheduleId(null); // Reset schedule ID when changing date
  };

  const handleTimeSelection = (time) => {
    setSelectedTime(time);

    // Find the selected schedule entry based on date and time
    const selectedSchedule = schedule.find(entry =>
      entry.sessionDate === selectedDate &&
      entry.sessionTime === time
    );

    // Debugging output
    console.log('Selected Date:', selectedDate);
    console.log('Selected Time:', time);
    console.log('Found Schedule Entry:', selectedSchedule);

    // Set the schedule ID if found
    if (selectedSchedule) {
      setSelectedScheduleId(selectedSchedule.scheduleId);

    } else {
      setSelectedScheduleId(null);

    }
  };

  const handlePrevClick = () => {
    if (sliderRef.current) {
      sliderRef.current.slickPrev();
    }
  };

  const handleNextClick = () => {
    if (sliderRef.current) {
      sliderRef.current.slickNext();
    }
  };

  const settings = {
    infinite: false,
    speed: 300,
    slidesToShow: 3,
    slidesToScroll: 2,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const handleNavigate = () => {
    if (selectedScheduleId) {
      navigate('/OfflineStudentRegistration', {
        state: {
          scheduleId: selectedScheduleId,
          instructorId: instructorId,
          pricing: pricing,
          hours: hours,
          gearBoxName: gearBoxName,
        },
      });
    } else {
      console.log('Cannot navigate: No schedule ID selected.');
    }
  };

  return (
    <div className="main bg-light">
      <div className="homeContainer pb-5">
        <div className="container-fluid">
          <div className="row align-items-center justify-content-center">
            <div className="col-12">
              <div className="row justify-content-center">
                <div className="col-lg-6 text-center">
                  <div className="mb-5 text-white desc mx-auto" data-aos="fade-up" data-aos-delay="200"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="bookOnlineBox5">
            <div className="HalfOne">
              <img src="images/car22.jpg" alt="Car" />
            </div>


            <div className="HalfTwo">
              {isLoading ? (
                <div className='loadingPaper'>
                  <div className='loadingBox'>

                  </div>
                </div>
              ) : uniqueDates.length > 0 ? (
                <>
                  <div className="innerHalf">
                    {instructorData ? (
                      <div className="instructor-info text-center">
                        <h3 className="mt-3 mb-0 text-center">
                          Now book a date & time for your<br /> lesson with {instructorData.firstName}
                        </h3>
                        <h5 className='text-secondary mt-1'>Gear box : {gearBoxName}</h5>
                      </div>
                    ) : (
                      <div>
                        <p className='text-danger'>Loading instructor information...</p>
                      </div>
                    )}

                    <div className="pricingOnM mt-4">
                      {isLoading ? (
                        <p className="text-center text-secondary mt-4">Loading dates...</p>
                      ) : uniqueDates.length > 0 ? (
                        <>
                          <button className='slideBtn position-absolute left-n0 ' onClick={handlePrevClick}>
                            <i className="fa-solid fa-chevron-left"></i>
                          </button>
                          <Slider {...settings} ref={sliderRef}>
                            {uniqueDates.map((date) => (
                              <div key={date.fullDate} className="col-12">
                                <button
                                  className={selectedDate === date.fullDate ? 'activePricingBox' : 'pricingBox'}
                                  onClick={() => handleDateSelection(date.fullDate)}
                                >
                                  <b className="m-0">{date.day}</b>
                                  <h2 className="m-0">{date.dayOfMonth}</h2>
                                  <p className="m-0">{date.month}</p>
                                </button>
                              </div>
                            ))}
                          </Slider>
                          <button className='slideBtn position-absolute right-n0 top-50' onClick={handleNextClick}>
                            <i className="fa-solid fa-chevron-right"></i>
                          </button>
                        </>
                      ) : (
                        <h2 className="text-center text-danger mt-4">
                          This instructor is currently unavailable for any scheduled sessions.
                        </h2>
                      )}
                    </div>
                    {calendarOpen && (

                      <div className="calD">
                        <div className="calendar-container mt-4 position-absolute onTop ">
                          <DatePicker
                            inline
                            selected={selectedDate ? new Date(selectedDate) : null} // Avoid invalid date
                            onChange={handleDateChange}
                            highlightDates={uniqueDates
                              .map((date) => {
                                // Validate and parse fullDate to a proper date
                                const parsedDate = Date.parse(date.fullDate);
                                return !isNaN(parsedDate) ? new Date(date.fullDate) : null;
                              })
                              .filter(Boolean) // Remove invalid dates (null)
                            }
                            includeDates={uniqueDates
                              .map((date) => {
                                // Validate and parse fullDate to a proper date
                                const parsedDate = Date.parse(date.fullDate);
                                return !isNaN(parsedDate) ? new Date(date.fullDate) : null;
                              })
                              .filter(Boolean) // Only allow valid dates for selection
                            }
                            minDate={new Date()} // Prevent selecting past dates
                          />
                        </div>
                      </div>
                    )}
                    <div className="calendar-button mt-4">
                      <a className="CalA" onClick={handleCalendarToggle}>
                        Open Calendar
                      </a>
                    </div>

                    {/* Calendar for Date Selection */}
                  </div>

                  {availableTimes.length > 0 && (
                    <div className="container-fluid mt-4">
                      <h5 className='text-center'>Available Times for {selectedDate}:</h5>
                      <div className="timeTnt mt-4">
                        {availableTimes.map((time, index) => (
                          <div
                            key={index}
                            className={`timeBomb ${selectedTime === time ? 'activeTimeBomb' : 'timeBomb'}`}
                            onClick={() => handleTimeSelection(time)}
                          >
                            <div className="mr-4">
                              <i className="fa-regular fa-clock"></i>
                            </div>
                            <p className='m-0'>
                              {time}
                            </p>
                          </div>
                        ))}
                      </div>
                    </div>
                  )}
                  <button className="btn btn-primary mt-4" onClick={handleNavigate} disabled={!selectedScheduleId}>Continue</button>
                </>
              ) : (
                <div className=' text-center px-5'>
                  <h2 className="text-center text-danger mt-4">
                    This instructor is currently unavailable for any scheduled sessions.
                  </h2>
                </div>

              )}
            </div>


          </div>
        </div>
      </div>
    </div>
  );
}

export default GetTime;
