import React from 'react';
import ROUTES from '../../navigations/Routes';
import { Link, Navigate, useNavigate } from 'react-router-dom';
import ImageSlider from '../ImageSlider';
import SliderText from '../SliderText';
import Slider from '../Slider2';
function Home() {
  const navigate = useNavigate();
  return (
    <div className="main">
      {/* <Navbar></Navbar> */}
      <div className="homeContainer pb-5">
        <ImageSlider></ImageSlider>
        <div className="w-100">
          <SliderText></SliderText>
        </div>

        <div className="untree_co-section col-lg-12 ">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12" data-aos="fade-up" data-aos-delay="100">
                <div className="feature">
                  <div className="row justify-content-center">
                    <div
                      className="col-lg-7 text-center"
                      data-aos="fade-up"
                      data-aos-delay="0"
                    >
                      <h2 className="line-bottom text-center my-4">
                        Driving School made easy.
                      </h2>
                      <h4>
                        Discover the reason why thousands of students select us
                        for their driver's education.
                      </h4>
                    </div>
                  </div>
                  <p>
                    <span className="uil uil-check-circle"></span>100% online,
                    hassle-free scheduling.
                  </p>
                  <p>
                    <span className="uil uil-check-circle"></span>30% cheaper
                    than comparable schools.
                  </p>
                  <p>
                    <span className="uil uil-check-circle"></span>Fully vetted
                    and MTO certified instructors.
                  </p>
                  <Link
                  to={ROUTES.findPostCode.name} className="btn btn-outline-primary py-3">
                    Book Online
                  </Link>
                  <Link
                    to={ROUTES.package.name}
                    className="btn btn-outline-primary mx-2 mb-3 py-3"
                  >
                    Register Courses
                  </Link>
                  <h6 className="my-3">
                    Booking will be completed within 30 sec.
                  </h6>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="container-fluid">
          <div
            className="untree_co bg-img"
            style={{
              backgroundImage: `url('./images/mouse.jpg')`,
            }}
          >
            <div className="container-fluid">
              <div className="untree_co_Text">
                <div className="row align-items-center justify-content-center text-center">
                  <div className="col-lg-12">
                    {/* <h2
                className="text-white my-5 offer"
                data-aos="fade-up"
                data-aos-delay="0"
              >
                50% OFF Limited Offer!!
              </h2> */}
                    <h3 className="mb-3 text-white">
                      Start preparing with our prep course!
                    </h3>
                    <h5
                      className="text-white h5"
                      data-aos="fade-up"
                      data-aos-delay="100"
                    >
                      We will give you a full refund if you fail your G1
                      knowledge exam on the first try.
                    </h5>
                    <p>
                      <Link
                        to={ROUTES.login.name}
                        className="btn btn-primary"
                        data-aos="fade-up"
                        data-aos-delay="200"
                      >
                        Practice Test
                      </Link>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/*  */}
        <div class="untree_co-section">
          <div class="container-fluid">
            <div class="row justify-content-between">
              <div class="col-lg-5">
                <h2
                  class="line-bottom mb-4"
                  data-aos="fade-up"
                  data-aos-delay="0"
                  style={{ fontSize: 30 }}
                >
                  Advance Learning Programs
                </h2>
                <h5
                  data-aos="fade-up"
                  data-aos-delay="100"
                  className="text-justify text-black"
                >
                  With CSM Driving School, you can learn to drive from certified
                  driving instructors. CSM instructors across all province
                  undergo rigorous training, routine background checks, and
                  drive brand-new, impeccably maintained cars. During our
                  driving lessons at CSM Driving School, we like to make sure
                  that our students receive the greatest possible road training.
                  <br />
                  To us, their road safety is the most important thing. In
                  addition to the standard online BDE Course and a variety of
                  driving packages, CSM Driving School offers the Advance
                  Driving Program.
                </h5>
              </div>
              <div class="col-lg-6" data-aos="fade-up" data-aos-delay="400">
                <div class="bg-1"></div>
                <img
                  src="images/gs.jpg"
                  alt="Image"
                  class="img-fluid rounded mt-5"
                />
              </div>
            </div>
          </div>
        </div>
        {/*  */}
        {/* <div class="cardContainer" data-aos="fade-up" data-aos-delay="100">
          <img src="../../images/carTop.png" className="cardCar"></img>
          <div class="col-12 col-md-6 col-lg-3 cardChild">
            <div className="cardHeading">
              <h2 className="">Shoulder Drop Off</h2>
            </div>
            <div className="cardContent">
              <h5>
                If the vehicle drifts or falls onto a gravel shoulder, the
                student will learn how to recover and control it.
              </h5>
            </div>
          </div>
          <div class="col-12 col-md-6 col-lg-3 cardChild">
            <div className="cardHeading">
              <h2 class="">Head-On Collision Avoidance</h2>
            </div>
            <div class="cardContent">
              <h5>
                We instruct students on how to avoid head-on collisions and
                maintain control during them.
              </h5>
            </div>
          </div>

          <div class="col-12 col-md-6 col-lg-3 cardChild">
            <div className="cardHeading">
              <h2 class="">Emergency Escape Route</h2>
            </div>
            <div class="cardContent">
              <h5>
                We successfully instruct students in appropriate gaze and
                preparation for their upcoming drive, which is fundamentally the
                first step toward developing good driving skills.
              </h5>
            </div>
          </div>
          <div class="col-12 col-md-6 col-lg-3 cardChild">
            <div className="cardHeading">
              <h2 class="">Brake & Avoid(Threshold Braking)</h2>
            </div>
            <div class="cardContent">
              <h5>
                Discover how, even in slick conditions, threshold braking should
                enable you to stop in your lane fairly quickly and under
                control.
              </h5>
            </div>
          </div>
        </div> */}
      </div>
    </div>
  );
}
export default Home;
